import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import rootStore from '@vue-storefront/core/store';
import { BaseStore, Store, Field } from '@zento-lib/stores/BaseStore';
import { getCookie } from '@zento-lib/components/AnalyticsServices/helpers';
// eslint-disable-next-line local/ímport-casing
import type { ZentoFacebookEvent } from '@zento-lib/graphql.types';
import { RecursiveRequired, ExtractArrayType } from '@zento-lib/util/types';
import type { IVSFUser as User } from 'theme/@types/vsf/stores/user';

import { saveFacebookEvent } from './operations.graphql';

type Address = {
  telephone?: string;
  city?: string;
  region?: { region_code?: string };
  country_id?: string;
  postcode?: string;
};

export type Content = Omit<RecursiveRequired<ExtractArrayType<ZentoFacebookEvent['content']>>, 'delivery_category'> & {
  delivery_category?: string;
};

export type Data = Omit<RecursiveRequired<ZentoFacebookEvent['custom_data']>, 'content_category' | 'content_name'> & {
  content_name?: string;
  content_category?: string;
};

export type UserData = Omit<
  RecursiveRequired<ZentoFacebookEvent['user_data']>,
  'client_user_agent' | 'fbc' | 'fbp' | 'ip_address'
>;

type EventInput = Omit<RecursiveRequired<ZentoFacebookEvent>, 'content' | 'custom_data'> & {
  content: Content[];
  custom_data: Data;
};

@Store
export class FbqConversionApiStore extends BaseStore {
  /**
   * Mutation response keeper
   */
  @Field
  private message: string;

  /**
   * Submit form
   */
  public async send(payload: {
    content: Content[];
    data: Data;
    eventId: string;
    eventName: string;
    sourceUrl: string;
    userData?: UserData;
  }) {
    const user = rootStore.state.user.current as User['state']['current'];
    let defaultAddress: Address = {
      telephone: '',
      city: '',
      region: { region_code: '' },
      country_id: '',
      postcode: '',
    };

    if (user && user.addresses && user.addresses.length) {
      defaultAddress = user.addresses.find((address) => address.default_billing === true) ?? user.addresses[0];
    }

    const input: EventInput = {
      content: payload.content,
      custom_data: payload.data,
      event_id: payload.eventId,
      name: payload.eventName,
      source_url: this.context.config.zento.theme.storeData.storeUrl + payload.sourceUrl,
      store_id: currentStoreView().storeId,
      time: Math.floor(new Date().getTime() / 1000).toString(),
      user_data: {
        client_user_agent: typeof navigator !== 'undefined' ? navigator.userAgent : '',
        fbc: getCookie('_fbc'),
        fbp: getCookie('_fbp'),
        ip_address: '',
        ...(user
          ? {
              first_name: user.firstname,
              last_name: user.lastname,
              email: user.email,
              phone: defaultAddress.telephone,
              city: defaultAddress.city,
              region: defaultAddress.region.region_code,
              country: defaultAddress.country_id,
              zip: defaultAddress.postcode,
            }
          : payload.userData
          ? { ...payload.userData }
          : null),
      },
    };

    return this.mutate(async () => {
      try {
        const resp = await this.dataSources.graphQl.queue({
          ...saveFacebookEvent,
          params: { input },
        });

        if (resp.saveFacebookEvent) {
          this.message = resp.saveFacebookEvent.message;
        }
      } catch (e) {
        console.error('Fbq Conversion Api Store (send): ', e);
      }
    });
  }

  public get getMessage(): string | null {
    return this.message ?? null;
  }
}
